import { Directive, effect, ElementRef, input, OnDestroy, signal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActiveDataPoint, Chart, ChartData, ChartOptions, ChartType } from 'chart.js';

@UntilDestroy()
@Directive({
  selector: '[appChartjs]',
  standalone: true,
})
export class ChartjsDirective implements OnDestroy {
  data = input.required<ChartData>();
  options = input.required<ChartOptions>();
  type = input<ChartType>('line');
  preselectLastElement = input<boolean>(true);

  height = input<number>(150);

  private ch = signal<Chart | undefined>(undefined);

  constructor(private el: ElementRef<HTMLCanvasElement>) {
    effect(() => {
      if (this.data() && this.options()) {
        if (!this.ch()) {
          this.ch.set(
            new Chart(this.el.nativeElement, {
              type: this.type(),
              data: this.data(),
              options: this.options(),
            })
          );

          this.ch()!.resize();

          if (this.preselectLastElement() && this.data().datasets[0].data.length > 0) {
            let lastElementWithDataIndex = 0;
            for (let i = this.data().datasets[0].data.length - 1; i >= 0; i--) {
              if (this.data().datasets[0].data[i] !== 0) {
                lastElementWithDataIndex = i;
                break;
              }
            }
            this.selectTooltipInChart(lastElementWithDataIndex, { shouldUpdateChart: true });
          }
        } else {
          this.ch()!.data = this.data();
          this.ch()!.options = this.options();

          const currentIndex = this.ch()!.tooltip?.getActiveElements()[0]?.index;

          // This is important so that external tooltips get updated on input data change
          if (currentIndex) {
            requestAnimationFrame(() => {
              this.selectTooltipInChart(currentIndex, { shouldUpdateChart: false });
            });
          }

          this.ch()!.update();
          this.ch()!.resize();
        }
      }
    });
  }

  private selectTooltipInChart(indexToSelect: number, options?: { shouldUpdateChart?: boolean }) {
    const activeElements: ActiveDataPoint[] = [{ datasetIndex: 0, index: indexToSelect }];

    // We use this to also activate the tooltips for the second and third datasets (ex. PV1 and PV2)
    if (this.data().datasets[1]) {
      activeElements.push({ datasetIndex: 1, index: indexToSelect });
    }

    if (this.data().datasets[2]) {
      activeElements.push({ datasetIndex: 2, index: indexToSelect });
    }

    this.ch()?.tooltip!.setActiveElements(activeElements, { x: 0, y: 0 });

    if (options?.shouldUpdateChart) {
      this.ch()?.update();
    }
  }

  ngOnDestroy() {
    this.ch()?.destroy();
  }
}
